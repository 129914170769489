/* global BigInt */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Position from './Position';
import { Card } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ethers } from 'ethers';
import axios from 'axios';

const Q96 = Math.pow(2, 96);
const Q128 = Math.pow(2, 128);

const LoaderCard = styled(Card)`
  max-width: 600px;
  min-width: 400px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LoadingCard = () => (
  <LoaderCard>
    <Skeleton variant="rectangular" height={150} />
    <Skeleton variant="text" width="80%" />
    <Skeleton variant="text" width="60%" />
    <Skeleton variant="text" width="90%" />
  </LoaderCard>
);

const uniPoolAbi = [
  {
    "inputs": [],
    "name": "slot0",
    "outputs": [
      { "internalType": "uint160", "name": "sqrtPriceX96", "type": "uint160" },
      { "internalType": "int24", "name": "tick", "type": "int24" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeGrowthGlobal0X128",
    "outputs": [
      { "internalType": "uint256", "name": "", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeGrowthGlobal1X128",
    "outputs": [
      { "internalType": "uint256", "name": "", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "int24", "name": "tick", "type": "int24" }],
    "name": "ticks",
    "outputs": [
      { "internalType": "uint128", "name": "liquidityGross", "type": "uint128" },
      { "internalType": "int128", "name": "liquidityNet", "type": "int128" },
      { "internalType": "uint256", "name": "feeGrowthOutside0X128", "type": "uint256" },
      { "internalType": "uint256", "name": "feeGrowthOutside1X128", "type": "uint256" }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

function isStablecoin(symbol){
  const array=['USDC', 'USDT', 'DAI'];
  return array.includes(symbol);
}


const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const PositionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
`;

async function getTokenInfo(tokenAddress, provider) {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    [
      'function symbol() view returns (string)',
      'function decimals() public view returns (uint8)'
    ],
    provider
  );

  
  return [await tokenContract.symbol(), await tokenContract.decimals()];
}


function PositionInfo({ isConnected, address, supportedAssets, provider, networkConfig }) {
  const [positions, setPositions] = useState([]);
  const [hedges, setHedges] = useState([]);
  const [showHedge, setShowHedge] = useState(false); 
  const [inRange, setInRange] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('authToken');

  useEffect(() => {

    function tokenAmountsAt(price, liquidity, tickLower, tickUpper, token0Decimals, token1Decimals, sqrtPriceX96) {
      if (liquidity === 0n) {
          return [0n, 0n, 0, 0];
      }
  
      const Q96 = 2n ** 96n;

      console.log('Price: '+ price)
      console.log('Liquidity: '+ liquidity)
  
      //const priceRatio = BigInt(Math.floor((price * (10 ** token1Decimals)) / (10 ** token0Decimals)));
      //const sqrtPriceX96 = BigInt(Math.floor(Math.sqrt(Number(price)) * (2 ** 96)));

      console.log('priceRatio: '+ price)
      console.log('sqrtPriceX96_true: '+ sqrtPriceX96)


      let isInRange = true;

      const sqrtRatioA = (Math.sqrt(1.0001 ** tickLower));
      const sqrtRatioB = (Math.sqrt(1.0001 ** tickUpper));

      console.log('sqrtRatioA: '+ sqrtRatioA)
      console.log('sqrtRatioB: '+ sqrtRatioB)

      const ratio = Number(sqrtPriceX96) / Number(Q96); // Keep this conversion to Number
      const squaredRatio = ratio ** 2;
      const currentTick = Math.floor(Math.log(squaredRatio) / Math.log(1.0001));

      console.log(currentTick)

      let amount0Wei = 0n;
      let amount1Wei = 0n;

      if (currentTick < tickLower) {
        amount0Wei = (Number(liquidity) * Number(sqrtRatioB - Number(sqrtRatioA))) / (Number(sqrtRatioA) * Number(sqrtRatioB) / Number(Q96));
        isInRange = false;
      } else if (currentTick >= tickUpper) {
        amount1Wei = Number(liquidity) * (Number(sqrtRatioB) - Number(sqrtRatioA)) / Number(Q96);
        isInRange = false;
      } else {
          console.log(ratio);
          console.log(Number(sqrtRatioB));
          console.log(liquidity)
          console.log('I am here ' + String((ratio) * Number(sqrtRatioB)));
          amount0Wei = (Number(liquidity) * (Number(sqrtRatioB) - Number(ratio))  / (Number(ratio) * Number(sqrtRatioB)));
          amount1Wei = (Number(liquidity) * (Number(ratio) - Number(sqrtRatioA)));
      }

      const amount0Human = Math.floor(Math.abs(Number(amount0Wei) / (10 ** token0Decimals))*100)/100;
      const amount1Human = Math.floor(Math.abs(Number(amount1Wei) / (10 ** token1Decimals))*100)/100;
  
      return [amount0Wei, amount1Wei, amount0Human, amount1Human, isInRange];
  }  
  
  function normalizePrices(price, priceUpper, priceLower, token0Symbol, token1Symbol) {
      if (isStablecoin(token0Symbol)) {
          return [1 / price, 1 / priceUpper, 1 / priceLower];
      } else if (isStablecoin(token1Symbol)) {
          return [price, priceLower, priceUpper];
      } else {
          throw new Error("No USD token found in position");
      }
  }

  async function approveAutoExitContract(tokenId, networkConfig, provider, signer) {
    const autoExitContract = new ethers.Contract(
      networkConfig.AutoExitAddress,
      ['function approve(address spender, uint256 tokenId) public'],
      signer
    );
  
    await autoExitContract.approve(networkConfig.AutoExitAddress, tokenId);
  }

  async function getFees(poolContract, tickLower, tickUpper, fees0, fees1, liquidity, token0Decimals, token1Decimals, currentTick) {
    const tickLow = await poolContract.ticks(tickLower);
    const tickHigh = await poolContract.ticks(tickUpper);

    const feeGrowthGlobal0 = await poolContract.feeGrowthGlobal0X128();
    const feeGrowthGlobal1 = await poolContract.feeGrowthGlobal1X128();
    const tickLowerFeeGrowthOutside0 = tickLow.feeGrowthOutside0X128;
    const tickUpperFeeGrowthOutside0 = tickHigh.feeGrowthOutside0X128;
    const feeGrowthInside0 = fees0;
    const tickLowerFeeGrowthOutside1 = tickLow.feeGrowthOutside1X128;
    const tickUpperFeeGrowthOutside1 = tickHigh.feeGrowthOutside1X128;
    const feeGrowthInside1 = fees1;

    const Q128 = BigInt(2 ** 128);

    let tickUpperFeeGrowthAbove0, tickUpperFeeGrowthAbove1;
    if (currentTick >= tickUpper) {
        tickUpperFeeGrowthAbove0 = BigInt(feeGrowthGlobal0) - BigInt(tickUpperFeeGrowthOutside0);
        tickUpperFeeGrowthAbove1 = BigInt(feeGrowthGlobal1) - BigInt(tickUpperFeeGrowthOutside1);
    } else {
        tickUpperFeeGrowthAbove0 = BigInt(tickUpperFeeGrowthOutside0);
        tickUpperFeeGrowthAbove1 = BigInt(tickUpperFeeGrowthOutside1);
    }

    let tickLowerFeeGrowthBelow0, tickLowerFeeGrowthBelow1;
    if (currentTick >= tickLower) {
        tickLowerFeeGrowthBelow0 = BigInt(tickLowerFeeGrowthOutside0);
        tickLowerFeeGrowthBelow1 = BigInt(tickLowerFeeGrowthOutside1);
    } else {
        tickLowerFeeGrowthBelow0 = BigInt(feeGrowthGlobal0) - BigInt(tickLowerFeeGrowthOutside0);
        tickLowerFeeGrowthBelow1 = BigInt(feeGrowthGlobal1) - BigInt(tickLowerFeeGrowthOutside1);
    }


    const frT10 = BigInt(feeGrowthGlobal0) - tickLowerFeeGrowthBelow0 - tickUpperFeeGrowthAbove0;
    const frT11 = BigInt(feeGrowthGlobal1) - tickLowerFeeGrowthBelow1 - tickUpperFeeGrowthAbove1;

    const uncollectedFees0 = (BigInt(liquidity) * (frT10 - BigInt(feeGrowthInside0))) / Q128;
    const uncollectedFees1 = (BigInt(liquidity) * (frT11 - BigInt(feeGrowthInside1))) / Q128;

    const uncollectedFeesAdjusted0 = Number(uncollectedFees0) / (10 ** token0Decimals);
    const uncollectedFeesAdjusted1 = Number(uncollectedFees1) / (10 ** token1Decimals);

    console.log(uncollectedFeesAdjusted0, uncollectedFeesAdjusted1)

    return {
        token0: uncollectedFeesAdjusted0,
        token1: uncollectedFeesAdjusted1
    };
}

  const fetchPositions = async () => {
    setIsLoading(true);
    try {

      const uniFactoryContract = new ethers.Contract(
        networkConfig.uniswapFactoryAddress,
        [
          {
            "inputs": [
              { "internalType": "address", "name": "", "type": "address" },
              { "internalType": "address", "name": "", "type": "address" },
              { "internalType": "uint24", "name": "", "type": "uint24" }
            ],
            "name": "getPool",
            "outputs": [
              { "internalType": "address", "name": "", "type": "address" }
            ],
            "stateMutability": "view",
            "type": "function"
          }
        ],
        provider
      );

      const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
          'function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)',
          'function positions(uint256 tokenId) external view returns (uint96 nonce, address operator, address token0, address token1, uint24 fee, int24 tickLower, int24 tickUpper, uint128 liquidity, uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, uint128 tokensOwed0, uint128 tokensOwed1)',
          'function balanceOf(address owner) external view returns (uint256)',
        ],
        provider
      );

      console.log(address)

      const positionCount = await contract.balanceOf(address);
      const positionsPromises = [];

      console.log(positionCount);

      for (let i = 0; i < positionCount; i++) {
        const tokenId = await contract.tokenOfOwnerByIndex(address, i);
        const positionData = await contract.positions(tokenId);

        const token0Info = await getTokenInfo(positionData.token0, provider);
        const token1Info = await getTokenInfo(positionData.token1, provider);

        console.log(token0Info);

        const token0Symbol = token0Info[0];
        const token1Symbol = token1Info[0];

        const token0Decimals = token0Info[1];
        const token1Decimals = token1Info[1];

        // Load the pool contract
        const poolAddress = await uniFactoryContract.getPool(positionData.token0, positionData.token1, positionData.fee);
        const poolContract = new ethers.Contract(
          poolAddress,
          uniPoolAbi,
          provider
        );

        // Fetch slot0 to get the current price
        const slot0 = await poolContract.slot0();
        const sqrtPriceX96 = slot0[0];
        const price = (sqrtPriceX96 / Q96) ** 2 * 10 ** token0Decimals / 10 ** token1Decimals;

        // Normalize price to USD
        const normalizedPrice = isStablecoin(token0Symbol) ? (1 / price) : price;

        const { liquidity, tickLower, tickUpper } = positionData;

        // Calculate token amounts at current price
        const [amount0Wei, amount1Wei, amount0, amount1, inRange] = tokenAmountsAt(
          price,
          BigInt(positionData.liquidity),
          positionData.tickLower,
          positionData.tickUpper,
          token0Decimals,
          token1Decimals,
          sqrtPriceX96
      );

      setInRange(inRange);

      console.log('amounts',amount0Wei, amount1Wei)

        // Calculate uncollected fees
        const fees0 = positionData.feeGrowthInside0LastX128;
        const fees1 = positionData.feeGrowthInside1LastX128;

        const uncollectedFees = await getFees(poolContract, tickLower, tickUpper, fees0, fees1, liquidity, token0Decimals, token1Decimals);

        let token0addr = positionData.token0;
        let token1addr = positionData.token1;
        let positionId = tokenId;

        positionsPromises.push({
          ...positionData,
          positionId,
          token0Symbol,
          token1Symbol,
          price,
          normalizedPrice,
          amount0,
          amount1,
          uncollectedFees,
          token0addr,
          token1addr,
          token0Decimals,
          token1Decimals
        });

        console.log(amount0, amount1);
      }

      const positionsData = await Promise.all(positionsPromises);
      setPositions(positionsData);
      setIsLoading(false);
      
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching positions:', error);
    }
  };

  const fetchHedges = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get('https://api.divedao.io/api/get_hedges', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setHedges(response.data.hedges);

      const signer = provider.getSigner();
      for (const hedge of response.data.hedges) {
        await approveAutoExitContract(hedge.position_id, networkConfig, provider, signer);
      }
    } catch (error) {
      console.error('Error fetching hedges:', error);
    }
  };

    console.log(isConnected);

    fetchHedges();
    fetchPositions();
  }, [isConnected, networkConfig]);
  return (
    <div>
      <h2>Positions for Account: {address}</h2>
      {isLoading ? (
        <LoadingCard />
      ) : (
        positions.map((position, index) => {
        const { positionId, token0Symbol, token1Symbol, liquidity, tickLower, tickUpper } = position;

        const name = `${token0Symbol} / ${token1Symbol}`;
        const tokens = [token0Symbol, token0Symbol];

        const hedge = hedges.find(h => h.position_id === position.id);
        const hedgeStatus = hedge ? hedge.status : '';
        const isHedgeable = supportedAssets.assets.some(asset => tokens.includes(asset));

        return (
          <Position 
            key={index}
            position={position}
            id={positionId}
            name={name}
            inRange={inRange}
            tickLower={tickLower}
            tickUpper={tickUpper}
            totalUSD={position.normalizedPrice}
            amount0={position.amount0}
            amount1={position.amount1}
            feeAPR={0}
            isHedgeable={isHedgeable}
            showHedge={showHedge}
            setShowHedge={setShowHedge}
            hedgeStatus={hedgeStatus}
            network={networkConfig}
          />
        );
      }))}
    </div>
  );
}

export default PositionInfo;