// src/components/Position.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, Tooltip, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';
import axios from 'axios';
import TokenDetails from './TokenDetails';

const PositionCard = styled(Card)`
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Label = styled(Typography)`
  color: #666;
  font-weight: 500;
`;

const Value = styled(Typography)`
  font-weight: 700;
`;

const RangeIndicator = styled(Typography)`
  font-weight: 700;
  color: ${props => props.inRange ? '#4caf50' : '#f44336'};
`;

const StyledTextField = styled(TextField)`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

function tickToPrice(tick, decimals0, decimals1) {
  return (Math.pow(1.0001, tick)) / (Math.pow(10, decimals1 - decimals0));
}


const Position = ({ position, id, name, inRange, tickLower, tickUpper, totalUSD, amount0, amount1, feeAPR, isHedgeable, onApproveHedge, network }) => {
  const {
    liquidity,
    pool,
    token0Symbol,
    token1Symbol,
    token0addr,
    token1addr,
    token0Decimals,
    token1Decimals
  } = position;

  const [hedgeSchema, setHedgeSchema] = useState([
    {
      "fr": 0.45,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.15,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    }
  ]);

  const [showHedge, setShowHedge] = useState(false);
  const [ButtonName, setButtonName] = useState('Hedge'); 

  const handleSchemaChange = (index, key, value) => {
    const newSchema = [...hedgeSchema];
    newSchema[index][key] = value;
    setHedgeSchema(newSchema);
  }

  const handleChangeShow = ()  => {
    if (showHedge) setButtonName('Hedge'); else setButtonName('Decline');
    setShowHedge(!showHedge)

  }

  const handleApproveHedge = async (positionId) => {
    try {
      console.log(Number(positionId));
      const token = localStorage.getItem('authToken');
      const response = await axios.post('https://api.divedao.io/api/hedge', {
        chain: 'arbitrum',
        position_id: Number(positionId),
        schema: hedgeSchema,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('Hedge approved successfully!');
      } else {
        alert('Failed to approve hedge.');
      }
    } catch (error) {
      console.error('Error approving hedge:', error);
    }
  };

  return (
    <PositionCard>
      <CardContent>
        <InfoRow>
          <Label variant="subtitle1">Token Pair:</Label>
          <a href={`https://app.uniswap.org/pools/${id}?chain=${network.chain}`}  target="_blank" variant="subtitle1">{name}</a>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token0addr} symbol={token0Symbol} network={network} />
          <Value variant="subtitle1">{amount0}</Value>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token1addr} symbol={token1Symbol} network={network} />
          <Value variant="subtitle1">{amount1}</Value>
        </InfoRow>
        
        
        <InfoRow>
          <Label variant="subtitle1">Range Status:</Label>
          <RangeIndicator variant="subtitle1" inRange={inRange}>
            {inRange ? '🟢 In Range' : '🔴 Out of Range'}
          </RangeIndicator>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Price Range:</Label>
          <Value variant="subtitle1">$ {Math.round(tickToPrice(tickLower,token0Decimals, token1Decimals)*100)/100} - $ {Math.round(tickToPrice(tickUpper,token0Decimals, token1Decimals)*100)/100}</Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Current Price:</Label>
          <Value variant="subtitle1">$ {Math.round(totalUSD*100)/100}</Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Position Size:</Label>
          <Value variant="subtitle1">$ {Math.floor((amount0 + amount1)*100)/100} </Value>
        </InfoRow>

        {isHedgeable ? (
          <div>
            <Tooltip title="Decline the hedge and hide options" arrow>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleApproveHedge(id)}
                style={{ marginRight: 10 }}
              >
                {ButtonName}
              </Button>
            </Tooltip>
            <IconButton
              onClick={() => setShowHedge(!showHedge)}
              style={{ marginLeft: 10 }}
            >
              <SettingsIcon />
            </IconButton>
            {showHedge && (
              <div>
                <StyledTextField
                  multiline
                  rows={8}
                  variant="outlined"
                  value={JSON.stringify(hedgeSchema, null, 2)}
                  onChange={(e) => setHedgeSchema(JSON.parse(e.target.value))}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowHedge(false)}
                  style={{ marginTop: 10 }}
                >
                  Save
                </Button>
              </div>
            )}
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Cannot hedge this position.
            </Typography>
          )}


      </CardContent>
    </PositionCard>
  );
};

export default Position;
